<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card>
          <destination-summary />
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <crc-and-grantee-summary />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="false"></slot>
    </div>
  </div>
</template>

<script>
  import DestinationSummary from "@/modules/stock/destination-reconditioning/views/components/DestinationSummary";
  import CRCAndGranteeSummary from "@/modules/stock/destination-reconditioning/views/components/CRCAndGranteeSummary";

  export default {
    name: "SummaryStep",

    components: {
      DestinationSummary,
      CrcAndGranteeSummary: CRCAndGranteeSummary
    }
  };
</script>

<style lang="scss" scoped></style>
