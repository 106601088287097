<template>
  <div class="d-flex flex-column">
    <div class="px-5 pt-5">
      <h2>Resumo da Destinação</h2>
      <p>
        Verifique os equipamentos e a quantidade selecionada antes de dar
        prosseguimento
      </p>
    </div>
    <v-divider></v-divider>
    <div>
      <v-data-table
        :headers="headers"
        :items="selectedEquipmentsStore"
        :items-per-page="selectedEquipmentsStore.length"
        hide-default-footer
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "DestinationSummary",

    data: () => ({
      headers: [
        { text: "Equipamento", value: "equipment" },
        { text: "Marca", value: "brand" },
        { text: "Modelo", value: "model" },
        { text: "Quantidade", value: "selectedAmount" }
      ]
    }),

    computed: {
      ...mapGetters("stock/destinationReconditioning", {
        selectedEquipmentsStore: "getCreateDestinationSelectedEquipments"
      })
    }
  };
</script>

<style lang="scss" scoped></style>
