<template>
  <div v-if="initialized">
    <div class="px-5 pt-6 form-container">
      <h3>Tipo</h3>

      <v-row dense>
        <v-col>
          <v-radio-group v-model="form.type" row>
            <v-radio
              color="primary-color-1"
              label="Pessoa jurídica"
              value="pj"
            ></v-radio>
            <v-radio
              color="primary-color-1"
              label="Pessoa física"
              value="pf"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <form-grantee-pf
        v-if="form.type === personTypes.PF"
        :form="form"
        :fnValidateForm="validateForm"
      />

      <form-grantee-pj
        v-else-if="form.type === personTypes.PJ"
        :form="form"
        :fnValidateForm="validateForm"
      />
    </div>
  </div>
</template>

<script>
  import FormGranteePF from "@/modules/stock/destination-reconditioning/views/components/FormGranteePF";
  import FormGranteePJ from "@/modules/stock/destination-reconditioning/views/components/FormGranteePJ";

  import { personTypes } from "@/modules/agenda/enums/personTypes.enum";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "RegisterGrantee",

    components: {
      FormGranteePf: FormGranteePF,
      FormGranteePj: FormGranteePJ
    },

    data: () => ({
      form: undefined,
      initialized: false
    }),

    mounted() {
      this.form = this.formGranteeStore;
      //this.setCreateDestinationGranteeInvalid(true);
      this.initialized = true;
    },

    watch: {
      form: {
        deep: true,
        handler: function(newVal) {
          if (this.initialized) {
            this.setCreateDestinationFormGrantee(newVal);
          }
        }
      }
    },

    computed: {
      ...mapGetters("stock/destinationReconditioning", {
        formGranteeStore: "getCreateDestinationFormGrantee"
      }),

      personTypes() {
        return personTypes;
      }
    },

    methods: {
      ...mapActions("stock/destinationReconditioning", [
        "setCreateDestinationFormGrantee",
        "setCreateDestinationGranteeInvalid"
      ]),

      validateForm(validationObserver) {
        const invalid =
          validationObserver?.flags?.invalid === undefined
            ? true
            : validationObserver.flags.invalid;

        const pristine =
          validationObserver?.flags?.pristine === undefined
            ? true
            : validationObserver.flags.pristine;

        this.setCreateDestinationGranteeInvalid(invalid || pristine);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form-container {
    height: 600px;
    overflow-y: auto;
  }
</style>
