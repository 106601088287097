<template>
  <div class="pt-7">
    <div class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar Donatário"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>

    <v-divider></v-divider>

    <div class="data-container mb-4 mx-5 px-2">
      <v-radio-group v-model="selectedGrantee">
        <v-radio
          v-for="(grantee, index) in grantees"
          :key="`grantee-${index}`"
          color="primary-color-1"
          :value="grantee.id"
          class="mt-1"
        >
          <template slot="label">
            <span class="black-1--text name">{{ grantee.name }}</span>
            <span class="black-3--text address">{{ grantee.address }}</span>
          </template>
        </v-radio>
      </v-radio-group>

      <div>
        <infinite-loading spinner="waveDots" @infinite="loadMore">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
  import InfiniteLoading from "vue-infinite-loading";

  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";

  import { debounce, pickBy, identity } from "lodash";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectGrantee",

    components: {
      InfiniteLoading
    },

    data: () => ({
      grantees: [],

      filterPristine: true,
      searchInput: "",
      page: 1,
      limit: 15
    }),

    async mounted() {
      this.grantees = await this.fetchGrantees();
    },

    computed: {
      ...mapGetters("stock/destinationReconditioning", {
        selectedGranteeStore: "getCreateDestinationSelectedGrantee"
      }),

      selectedGrantee: {
        get() {
          return this.selectedGranteeStore;
        },
        set(newVal) {
          this.setCreateDestinationSelectedGrantee(newVal);
          this.setCreateDestinationGranteeInvalid(!newVal);
        }
      }
    },

    methods: {
      ...mapActions("stock/destinationReconditioning", [
        "getGranteesSimplified",
        "setCreateDestinationSelectedGrantee",
        "setCreateDestinationGranteeInvalid"
      ]),

      clearSearch() {
        this.page = 1;
        this.grantees = [];
      },

      clearSelectedGrantee() {
        this.selectedGrantee = undefined;
      },

      debounceInput: debounce(async function(value) {
        this.clearSearch();

        this.filterPristine = false;
        this.searchInput = value;

        this.grantees = await this.fetchGrantees();
      }, 800),

      mountPayload() {
        const payload = {
          search: this.searchInput,
          page: this.page,
          limit: this.limit
        };

        return pickBy(payload, identity);
      },

      async fetchGrantees() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getGranteesSimplified(payload);

          return this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(item => {
          return {
            id: item.id,
            name: item.name,
            address: parseAdressSingleLine(item.address)
          };
        });
      },

      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.fetchGrantees();

        if (data.length) {
          this.grantees = [...this.grantees, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .name {
    width: 200px;
  }

  .address {
    font-size: 14px;
  }
</style>
