<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card class="table-page">
          <list-equipment-inventory
            v-if="showList"
            :filterByCrcId="selectedCrcId"
          />
          <div v-else class="inherit-height d-flex flex-column justify-center">
            <crc-not-found
              :title="['Para continuar', 'você deve selecionar uma CRC']"
            />
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <destination-select-crc @invalid="handleInvalid" />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="invalid"></slot>
    </div>
  </div>
</template>

<script>
  import ListEquipmentInventory from "@/modules/stock/destination-reconditioning/views/components/ListEquipmentInventory";
  import DestinationSelectCRC from "@/modules/stock/destination-reconditioning/views/components/DestinationSelectCRC";
  import CRCNotFound from "@/modules/crc/views/components/CRCNotFound";

  import { mapGetters } from "vuex";

  export default {
    name: "ListInventoryAndSelectCRC",

    components: {
      ListEquipmentInventory,
      DestinationSelectCrc: DestinationSelectCRC,
      CrcNotFound: CRCNotFound
    },

    data: () => ({
      invalid: true
    }),

    computed: {
      ...mapGetters("user", {
        userIsAdmin: "getUserIsAdmin"
      }),

      ...mapGetters("stock/destinationReconditioning", {
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      showList() {
        return this.userIsAdmin ? Boolean(this.selectedCRCStore?.id) : true;
      },

      selectedCrcId() {
        return this.selectedCRCStore?.id;
      }
    },

    methods: {
      handleInvalid(invalid) {
        this.invalid = invalid;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
