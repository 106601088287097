<template>
  <div>
    <v-row>
      <v-col cols="12">
        <field
          v-model="address.zipCode"
          type="cep"
          label="CEP"
          name="CEP"
          :customError="customErrorCep"
          :isRequired="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <field
          v-model="address.address"
          type="genericText"
          label="Endereço"
          name="address"
          :isRequired="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <field
          v-model="address.number"
          type="genericText"
          label="Número"
          name="number"
          :isRequired="true"
        />
      </v-col>
      <v-col cols="6">
        <field
          v-model="address.complement"
          type="genericText"
          label="Complemento"
          name="complement"
          :isRequired="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <field
          v-model="address.neighborhood"
          type="genericText"
          :disabled="disabled"
          label="Bairro"
          name="neighborhood"
          :isRequired="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <field
          v-model="address.city"
          type="genericText"
          :disabled="disabled"
          label="Cidade"
          name="city"
          :isRequired="true"
        />
      </v-col>
      <v-col cols="6">
        <field
          v-model="address.state.id"
          name="Estado"
          type="select"
          label="Estado"
          :disabled="disabled"
          :items="states"
          item-text="name"
          data-cy="state-select"
          item-value="id"
          :isRequired="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import Field from "@/modules/core/views/components/Field";

  import { states } from "@/modules/core/enums/states.enum";

  export default {
    name: "AddressForm",

    components: {
      Field
    },

    props: {
      address: {
        type: Object,
        required: false,
        default: () => {}
      }
    },

    mixins: [MasksMixin],

    data: () => ({
      states: [],
      disabled: true,
      customErrorCep: null
    }),

    mounted() {
      this.loadStates();
    },

    watch: {
      async "address.zipCode"(value) {
        await this.setZipCode(value);
        this.$emit("update:address", this.address);
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("crc", ["getAddressByZipCode"]),

      clearAddressFields() {
        this.address.address = "";
        this.address.neighborhood = "";
        this.address.city = "";
        this.address.state = {};
      },

      setCustomErrorCep(menssage) {
        this.customErrorCep = menssage;
      },

      async setZipCode(value) {
        if (value.length !== 8) {
          return this.clearAddressFields();
        }
        try {
          const data = await this.getAddressByZipCode(value);

          this.setCustomErrorCep(null);

          if (data) {
            this.address.address = data.logradouro;
            this.address.neighborhood = data.bairro;
            this.address.city = data.localidade;
            this.address.state.id = states.find(
              state => state.abbreviation === data.uf.toUpperCase()
            ).id;
          }

          if (data.erro) {
            this.handleError();
          }
        } catch (err) {
          this.handleError();
        }
      },

      handleError() {
        this.clearAddressFields();
        this.setCustomErrorCep("CEP não encontrado");
      },

      loadStates() {
        this.states = Object.values([
          { name: "Todos", abbreviation: null },
          ...states
        ]);
      }
    }
  };
</script>

<style></style>
