<template>
  <div class="table-page d-flex flex-column fill justify-space-between">
    <div class="inherit-height">
      <template v-if="showFilters">
        <div class="px-5 pt-7">
          <v-row justify="end">
            <v-col cols="12" md="5">
              <v-text-field
                @input="setFilterDebounced('search', $event)"
                solo
                flat
                full-width
                color="secondary-color-1"
                background-color="white-1"
                placeholder="Buscar (Ex: Equipamento)"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-if="items.length">
        <v-data-table
          :headers="headers"
          :items="items"
          :show-select="select"
          hide-default-footer
          :items-per-page="tableInfo.itemsPerPage"
          v-model="selectedEquipments"
        />
      </template>
      <div class="inherit-height d-flex flex-column justify-center" v-else>
        <inventory-not-found :title="['Nenhum equipamento em estoque']" />
      </div>
    </div>
    <div v-if="items.length">
      <pagination
        :currentPage="tableInfo.currentPage"
        :totalPages="tableInfo.totalPages"
        :itemsPerPage="tableInfo.itemsPerPage"
        @currentPageChanged="handleCurrentPageChange($event)"
        @itemsPerPageChanged="handleItemsPerPageChange($event)"
      />
    </div>
  </div>
</template>

<script>
  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import InventoryNotFound from "@/modules/stock/parts-inventory/view/components/InventoryNotFound";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "ListEquipmentInventory",

    props: {
      select: {
        type: Boolean,
        required: false,
        default: () => false
      },

      value: {
        type: null,
        required: false,
        default: () => []
      },

      useFilters: {
        type: Boolean,
        required: false,
        default: () => true
      },

      filterByCrcId: {
        type: Number,
        required: false
      }
    },

    components: {
      Pagination,
      InventoryNotFound
    },

    mixins: [TableHandler],

    data: () => ({
      items: [],
      crcs: [],

      filterEquipmentPristine: true,

      headers: [
        { text: "Equipamento", value: "equipment" },
        { text: "Marca", value: "brand" },
        { text: "Modelo", value: "model" },
        { text: "Quantidade", value: "amount" }
      ]
    }),

    watch: {
      filterByCrcId: {
        immediate: true,
        handler: function(newVal) {
          this.setFilter("crcId", newVal);
          this.clearFilter("search");
          this.filterEquipmentPristine = true;
        }
      }
    },

    mounted() {
      this.fetchData();
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),

      showFilters() {
        if (!this.items.length && this.filterEquipmentPristine) {
          return false;
        }
        if (this.items.length) {
          return this.useFilters;
        }
        return this.useFilters && !this.items.length && !this.filterPristine;
      },

      selectedEquipments: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions("stock/destinationReconditioning", [
        "getEquipmentsFromInventory"
      ]),

      async fetchData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getEquipmentsFromInventory(payload);

          this.setTableInfo(meta);
          this.items = this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(item => ({
          id: item.id,
          equipment:
            item.equipment?.equipmentType?.description || "não informado",
          brand: item.equipment.brand.description,
          model: item.equipment.model,
          amount: item.amount,
          selectedAmount: 0
        }));
      },

      updateData() {
        if (this.filters?.search?.length) {
          this.filterEquipmentPristine = false;
        }
        this.fetchData();
      }
    }
  };
</script>

<style lang="scss" scoped></style>
