<template>
  <div class="d-flex flex-column">
    <div class="pa-5">
      <h2>Quantidade de Equipamentos</h2>
      <p class="mt-2">
        Antes de avançar, informe a quantidade de cada equipamento selecionado
        que vai ser destinada a um donatário
      </p>
    </div>

    <v-divider></v-divider>
    <div class="pa-5">
      <template v-if="equipments.length">
        <div v-for="(equipment, index) in equipments" :key="index">
          <p>
            Quantos "{{ formatEquipmentDescription(equipment) }}" vão ser
            destinados?
          </p>
          <div class="d-flex justify-center mt-3">
            <number-field
              v-model="selectedEquipmentsWithAmount[index].selectedAmount"
              :max="equipment.amount"
            />
            <span
              class="error--text"
              v-if="selectedEquipmentsWithAmount[index].selectedAmount === 0"
            >
              *
            </span>
          </div>
        </div>
      </template>

      <template v-else>
        <inventory-not-found :title="['Selecione um equipamento']" />
      </template>
    </div>
  </div>
</template>

<script>
  import InventoryNotFound from "@/modules/stock/parts-inventory/view/components/InventoryNotFound";
  import NumberField from "@/modules/core/views/components/NumberField";

  export default {
    name: "EquipmentQuantities",

    props: {
      equipments: {
        type: Array,
        required: false,
        default: () => []
      },
      value: {
        type: null,
        required: false,
        default: () => []
      }
    },

    components: {
      NumberField,
      InventoryNotFound
    },

    computed: {
      selectedEquipmentsWithAmount: {
        get() {
          return this.equipments;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    },

    methods: {
      formatEquipmentDescription(equipment) {
        return `${equipment.equipment} ${equipment.brand} ${equipment.model}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-input--selection-controls.v-input {
    min-width: 250px;
  }
</style>
