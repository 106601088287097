var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('title-header',{attrs:{"title":_vm.$route.meta.title,"subtitle":_vm.subtitle}}),(_vm.stepper.currentStep === _vm.steps.LIST_EQUIPMENT_AND_SELECT_CRC)?_c('list-inventory-and-select-crc',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-0 mt-1 white--text next-step-button",attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleStartCreateDestination}},[_vm._v("Nova Destinação")])],1)]}}],null,false,3228466868)}):_vm._e(),(_vm.stepper.currentStep === _vm.steps.SELECT_EQUIPMENTS)?_c('select-equipment',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-1 black-3--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","color":"secondary-color-1","block":_vm.$isMobile,"outlined":""},on:{"click":_vm.handleBackActionOnFirstStep}},[_vm._v(" Voltar ")]),_c('v-btn',{staticClass:"mt-1 white--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleSelectedEquipments}},[_vm._v("Avançar")])],1)]}}],null,false,1870576867)}):_vm._e(),(_vm.stepper.currentStep === _vm.steps.SELECT_GRANTEE_AND_FINISH)?_c('select-grantee-and-finish',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex flex-wrap justify-end"},[_c('v-btn',{staticClass:"mt-1 black-3--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","color":"secondary-color-1","block":_vm.$isMobile,"outlined":""},on:{"click":_vm.handleBackActionOnSecondStep}},[_vm._v(" Voltar ")]),_c('v-btn',{staticClass:"mt-1 white--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleSelectOrCreateGrantee}},[_vm._v("Avançar")])],1)]}}],null,false,2716453096)}):_vm._e(),(_vm.stepper.currentStep === _vm.steps.SUMMARY)?_c('summary-step',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex flex-wrap justify-end"},[_c('v-btn',{staticClass:"mt-1 black-3--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","color":"secondary-color-1","block":_vm.$isMobile,"outlined":""},on:{"click":_vm.handleBackActionOnThirdStep}},[_vm._v(" Voltar ")]),_c('v-btn',{staticClass:"mt-1 white--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleCreateDestination}},[_vm._v("Concluir")])],1)]}}],null,false,263602483)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }