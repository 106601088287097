<template>
  <div>
    <v-row>
      <v-col cols="12" lg="5">
        <v-tabs
          active-class="tab-active"
          hide-slider
          background-color="transparent"
          v-model="tab"
        >
          <v-tab
            class="text-capitalize primary-color-2--text"
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <register-grantee :key="`register-${granteeTabKeyStore}`" />
            </v-tab-item>
            <v-tab-item>
              <select-grantee :key="`select-${granteeTabKeyStore}`" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
      <v-col cols="12" lg="7">
        <v-card>
          <destination-summary />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="granteeInvalidStore"></slot>
    </div>
  </div>
</template>

<script>
  import SelectGrantee from "@/modules/stock/destination-reconditioning/views/components/SelectGrantee";
  import RegisterGrantee from "@/modules/stock/destination-reconditioning/views/components/RegisterGrantee";
  import DestinationSummary from "@/modules/stock/destination-reconditioning/views/components/DestinationSummary";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectGranteeAndFinish",

    components: {
      SelectGrantee,
      RegisterGrantee,
      DestinationSummary
    },

    data: () => ({
      formGrantee: {},
      granteeInvalid: true,

      items: ["Cadastrar Donatário", "Selecionar Donatário"]
    }),

    computed: {
      ...mapGetters("stock/destinationReconditioning", {
        granteeTabStore: "getCreateDestinationGranteeTab",
        granteeTabKeyStore: "getCreateDestinationGranteeTabKey",
        granteeInvalidStore: "getCreateDestinationGranteeInvalid"
      }),

      tab: {
        get() {
          return this.granteeTabStore;
        },
        set(newVal) {
          this.setCreateDestinationGranteeTab(newVal);
        }
      }
    },

    methods: {
      ...mapActions("stock/destinationReconditioning", [
        "setCreateDestinationGranteeTab"
      ])
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .tab-active {
    background: white !important;
  }

  .v-tab {
    border-radius: 6px 6px 0 0;
    &[aria-selected="false"] {
      background: rgba(255, 255, 255, 0.12);
      color: white !important;
    }
  }

  .v-tabs-items {
    border-radius: 0 6px 6px 6px;
  }
</style>
