<template>
  <validation-observer ref="validationObserver">
    <v-form>
      <h3 class="mb-2">Dados Pessoais</h3>
      <v-row dense>
        <v-col cols="12">
          <field
            v-model="form.granteePF.name"
            type="genericText"
            label="Nome completo"
            name="firstName"
            @blur="validateForm"
            :isRequired="true"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <field
            v-model="form.email"
            type="email"
            label="E-mail"
            name="email"
            @blur="validateForm"
            :isRequired="true"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <field
            v-model="form.granteePF.cpf"
            type="cpf"
            label="CPF"
            name="cpf"
            @blur="validateForm"
            :isRequired="true"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <phone-field v-model="form.phones" :isRequired="true" />
        </v-col>
      </v-row>

      <h3>Endereço</h3>
      <address-form :address="form.address" @addressForm="updateAddress" />
    </v-form>
  </validation-observer>
</template>

<script>
  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";
  import AddressForm from "@/modules/core/views/components/AddressForm";

  import { ValidationObserver } from "vee-validate";

  export default {
    name: "FormGranteePF",

    props: {
      form: {
        type: Object,
        required: true
      },
      fnValidateForm: {
        type: Function,
        required: true
      }
    },

    components: {
      Field,
      PhoneField,
      ValidationObserver,
      AddressForm
    },

    mounted() {
      this.validateForm();
    },

    watch: {
      form: {
        deep: true,
        handler: function(_) {
          this.validateForm();
        }
      }
    },

    methods: {
      updateAddress(address) {
        this.form.address = address;
      },

      async validateForm() {
        await this.$refs.validationObserver.validate({ silent: true });
        this.fnValidateForm(this.$refs.validationObserver);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
