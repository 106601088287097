<template>
  <div class="d-flex flex-column">
    <div class="px-10 pt-5">
      <h2>Informações</h2>
      <v-row>
        <v-col cols="5" md="4"><span class="black-3--text">CRC:</span></v-col>
        <v-col cols="7" md="8'">{{ selectedCRCStore.name }}</v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="px-10 pt-5 mb-6">
      <h2>Donatário</h2>

      <v-row>
        <v-col cols="5" md="4">
          <span class="black-3--text">Nome:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ selectedGranteeStore.name }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4">
          <span class="black-3--text">Telefone(s):</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ phones }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4">
          <span class="black-3--text">{{ cpfOrCnpjLabel }}:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">
            {{ selectedGranteeStore.cpfOrCnpj | VMask(cpfOrCnpjMask) }}
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4"
          ><span class="black-3--text">E-mail:</span></v-col
        >
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ selectedGranteeStore.email }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4"
          ><span class="black-3--text">Endereço:</span></v-col
        >
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ address }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";
  import { personTypes } from "@/modules/agenda/enums/personTypes.enum";

  import { mapGetters } from "vuex";

  export default {
    name: "CRCAndGranteeSummary",

    mixins: [MasksMixin],

    computed: {
      ...mapGetters("stock/destinationReconditioning", {
        selectedGranteeStore: "getCreateDestinationSelectedGrantee",
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      phones() {
        return this.selectedGranteeStore.phones
          .map(phone => this.parsePhone(phone))
          .join(", ");
      },

      address() {
        return parseAdressSingleLine(this.selectedGranteeStore.address);
      },

      cpfOrCnpjLabel() {
        return this.selectedGranteeStore.type.toUpperCase() ===
          personTypes.PJ.toUpperCase()
          ? "CNPJ"
          : "CPF";
      },

      cpfOrCnpjMask() {
        return this.selectedGranteeStore.type.toUpperCase() ===
          personTypes.PJ.toUpperCase()
          ? this.masks.cnpj
          : this.masks.cpf;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
