<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card>
          <list-equipment-inventory
            :select="true"
            :useFilters="false"
            :filterByCrcId="selectedCRCStore.id"
            v-model="selectedEquipments"
          />
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <equipment-quantities :equipments="selectedEquipments" />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="invalid"></slot>
    </div>
  </div>
</template>

<script>
  import ListEquipmentInventory from "@/modules/stock/destination-reconditioning/views/components/ListEquipmentInventory";
  import EquipmentQuantities from "@/modules/stock/destination-reconditioning/views/components/EquipmentQuantities";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectEquipment",

    components: {
      ListEquipmentInventory,
      EquipmentQuantities
    },

    computed: {
      ...mapGetters("stock/destinationReconditioning", {
        selectedEquipmentsStore: "getCreateDestinationSelectedEquipments",
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      selectedEquipments: {
        get() {
          return this.selectedEquipmentsStore;
        },
        set(newVal) {
          this.setCreateDestinationSelectedEquipments(newVal);
        }
      },
      invalid() {
        return this.validateHasItemSelected() || this.validateItemsIsZero();
      }
    },

    methods: {
      ...mapActions("stock/destinationReconditioning", [
        "setCreateDestinationSelectedEquipments"
      ]),

      validateItemsIsZero() {
        return this.selectedEquipments.every(
          equipment => equipment.selectedAmount === 0
        );
      },

      validateHasItemSelected() {
        return !this.selectedEquipments?.length;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
