<template>
  <v-container>
    <title-header :title="$route.meta.title" :subtitle="subtitle" />

    <list-inventory-and-select-crc
      v-if="stepper.currentStep === steps.LIST_EQUIPMENT_AND_SELECT_CRC"
    >
      <template v-slot:actions="{ invalid }">
        <div class="d-flex justify-end">
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="ma-0 mt-1 white--text next-step-button"
            :block="$isMobile"
            @click="handleStartCreateDestination"
            >Nova Destinação</v-btn
          >
        </div>
      </template>
    </list-inventory-and-select-crc>

    <select-equipment v-if="stepper.currentStep === steps.SELECT_EQUIPMENTS">
      <template v-slot:actions="{ invalid }">
        <div class="d-flex justify-end">
          <v-btn
            type="button"
            color="secondary-color-1"
            class="mt-1 black-3--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            outlined
            @click="handleBackActionOnFirstStep"
          >
            Voltar
          </v-btn>
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="mt-1 white--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleSelectedEquipments"
            >Avançar</v-btn
          >
        </div>
      </template>
    </select-equipment>

    <select-grantee-and-finish
      v-if="stepper.currentStep === steps.SELECT_GRANTEE_AND_FINISH"
    >
      <template v-slot:actions="{ invalid }">
        <div class="d-flex flex-wrap justify-end">
          <v-btn
            type="button"
            color="secondary-color-1"
            class="mt-1 black-3--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            outlined
            @click="handleBackActionOnSecondStep"
          >
            Voltar
          </v-btn>
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="mt-1 white--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleSelectOrCreateGrantee"
            >Avançar</v-btn
          >
        </div>
      </template>
    </select-grantee-and-finish>

    <summary-step v-if="stepper.currentStep === steps.SUMMARY">
      <template v-slot:actions="{ invalid }">
        <div class="d-flex flex-wrap justify-end">
          <v-btn
            type="button"
            color="secondary-color-1"
            class="mt-1 black-3--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            outlined
            @click="handleBackActionOnThirdStep"
          >
            Voltar
          </v-btn>
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="mt-1 white--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleCreateDestination"
            >Concluir</v-btn
          >
        </div>
      </template>
    </summary-step>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import ListInventoryAndSelectCRC from "@/modules/stock/destination-reconditioning/views/components/ListInventoryAndSelectCRC";
  import SelectEquipment from "@/modules/stock/destination-reconditioning/views/components/SelectEquipment";
  import SelectGranteeAndFinish from "@/modules/stock/destination-reconditioning/views/components/SelectGranteeAndFinish";
  import SummaryStep from "@/modules/stock/destination-reconditioning/views/components/SummaryStep";

  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";
  import { personTypes } from "@/modules/agenda/enums/personTypes.enum";
  import { granteeTabs } from "@/modules/stock/destination-reconditioning/enums/granteeTabs.enum";

  import { mapActions, mapGetters } from "vuex";

  import store from "@/store";

  export default {
    name: "DestinationReconditioning",

    beforeRouteLeave: (to, from, next) => {
      store.dispatch("stock/destinationReconditioning/resetCreateDestination");
      next();
    },

    components: {
      TitleHeader,
      ListInventoryAndSelectCrc: ListInventoryAndSelectCRC,
      SelectEquipment,
      SelectGranteeAndFinish,
      SummaryStep
    },

    data: () => ({
      steps: {
        LIST_EQUIPMENT_AND_SELECT_CRC: 0,
        SELECT_EQUIPMENTS: 1,
        SELECT_GRANTEE_AND_FINISH: 2,
        SUMMARY: 3
      }
    }),

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),
      ...mapGetters("stock/destinationReconditioning", {
        stepper: "getCreateDestinationStepper",
        formGranteeStore: "getCreateDestinationFormGrantee",
        granteeTabStore: "getCreateDestinationGranteeTab",
        selectedGranteeStore: "getCreateDestinationSelectedGrantee",
        selectedEquipmentsStore: "getCreateDestinationSelectedEquipments",
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      subtitle() {
        if (
          this.stepper.currentStep === this.steps.LIST_EQUIPMENT_AND_SELECT_CRC
        ) {
          return undefined;
        }
        return `Passo ${this.stepper.currentStep} de ${this.stepper.maxSteps}`;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),

      ...mapActions("stock/destinationReconditioning", [
        "setCreateDestinationCurrentStep",
        "createGrantee",
        "createDonate",
        "getGranteeById",
        "resetCreateDestination",
        "setCreateDestinationSelectedGrantee"
      ]),

      handleStartCreateDestination() {
        this.setCreateDestinationCurrentStep(this.steps.SELECT_EQUIPMENTS);
      },

      handleSelectedEquipments() {
        this.setCreateDestinationCurrentStep(
          this.steps.SELECT_GRANTEE_AND_FINISH
        );
      },

      handleBackActionOnFirstStep() {
        this.setCreateDestinationCurrentStep(
          this.steps.LIST_EQUIPMENT_AND_SELECT_CRC
        );
      },

      handleBackActionOnSecondStep() {
        this.setCreateDestinationCurrentStep(this.steps.SELECT_EQUIPMENTS);
      },

      handleBackActionOnThirdStep() {
        this.setCreateDestinationCurrentStep(
          this.steps.SELECT_GRANTEE_AND_FINISH
        );
      },

      async selectOrRegisterGrantee() {
        if (this.granteeTabStore === granteeTabs.REGISTER) {
          return await this.registerGrantee(this.formGranteeStore);
        }
        return this.selectedGranteeStore;
      },

      mountPayloadRegisterGrantee(formGrantee) {
        formGrantee.phones = preparePhonesToSubmit(formGrantee.phones);

        if (formGrantee.type === personTypes.PJ) {
          formGrantee.granteePJ.agent.phones = preparePhonesToSubmit(
            formGrantee.granteePJ.agent.phones
          );
          delete formGrantee.granteePF;
        }

        if (formGrantee.type === personTypes.PF) {
          delete formGrantee.granteePJ;
        }

        return formGrantee;
      },

      mountPayloadRegisterDonate(granteeId) {
        const payload = {
          granteeId,
          stocks: this.selectedEquipmentsStore.map(equipment => ({
            id: equipment.id,
            amount: equipment.selectedAmount
          }))
        };

        if (this.userIsAdmin) {
          payload.crcId = this.selectedCRCStore.id;
        }

        return payload;
      },

      async registerGrantee(formGrantee) {
        const payload = this.mountPayloadRegisterGrantee(formGrantee);
        const response = await this.createGrantee(payload);
        return response.data.id;
      },

      async handleSelectOrCreateGrantee() {
        try {
          const granteeId = await this.selectOrRegisterGrantee();
          const { data } = await this.getGranteeById(granteeId);

          this.setCreateDestinationSelectedGrantee(data);

          this.setCreateDestinationCurrentStep(this.steps.SUMMARY);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async handleCreateDestination() {
        try {
          const granteeId = this.selectedGranteeStore.id;

          const payload = this.mountPayloadRegisterDonate(granteeId);
          const response = await this.createDonate(payload);

          this.toggleSnackbar({
            text: response?.message ?? "Doação efetuada com sucesso",
            type: "success"
          });

          this.resetCreateDestination();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style></style>
