<template>
  <div class="d-flex">
    <btn-icon-press
      class="mt-4"
      color="#95A9B2"
      icon="mdi-minus-circle"
      :fnClick="decrement"
      :fnClickStart="startDecrement"
      :fnClickStop="stopInterval"
    />
    <v-text-field
      class="field"
      placeholder="1~3"
      v-model="inputModel"
      :suffix="suffix"
      readonly
      outlined
    ></v-text-field>

    <btn-icon-press
      class="mt-4"
      color="#95A9B2"
      icon="mdi-plus-circle"
      :fnClick="increment"
      :fnClickStart="startIncrement"
      :fnClickStop="stopInterval"
    />
  </div>
</template>

<script>
  import BtnIconPress from "@/modules/core/views/components/BtnIconPress";

  export default {
    name: "NumberField",

    props: {
      value: {
        type: null,
        required: true
      },
      min: {
        type: Number,
        required: false,
        default: () => 0
      },
      max: {
        type: Number,
        required: false
      },
      suffix: {
        type: String,
        required: false,
        default: () => "Unid."
      }
    },

    components: {
      BtnIconPress
    },

    data: () => ({
      interval: undefined,
      throttle: 250
    }),

    computed: {
      inputModel: {
        get() {
          return this.value;
        },
        set(newVal) {
          if (!this.validate(newVal)) {
            return;
          }
          this.$emit("input", newVal);
        }
      }
    },

    methods: {
      decrement() {
        this.inputModel--;
      },

      startDecrement() {
        if (!this.interval) {
          this.interval = setInterval(() => this.decrement(), this.throttle);
        }
      },

      increment() {
        this.inputModel++;
      },

      startIncrement() {
        if (!this.interval) {
          this.interval = setInterval(() => this.increment(), this.throttle);
        }
      },

      stopInterval() {
        clearInterval(this.interval);
        this.interval = undefined;
      },

      validate(newVal) {
        if (newVal < this.min) {
          return false;
        }
        if (this.max && newVal > this.max) {
          return false;
        }
        return true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .field {
    width: 140px;
  }
</style>
